@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 21px;
}

body {
    font-family: 'Overpass', sans-serif;
    @apply
    px-2
    text-base
    text-white
    bg-slate-900
    overflow-x-hidden;
}

/********** sections **********/

.section-container {
    @apply
    container
    mx-auto
    max-w-sm lg:max-w-screen-lg xl:max-w-screen-xl;
}

.section-layout {
    @apply
    grid lg:grid-cols-2
    justify-items-center items-center;
}

.section-spacing {
    @apply mt-48 sm:mt-52 lg:mt-64;
}

.subsection-spacing {
    @apply mt-24 lg:mt-24;
}

.section-header {
    @apply
    flex flex-col items-center
    relative;

    h1 {
        @apply
        font-black
        text-3xl sm:text-4xl lg:text-5xl
        tracking-wide uppercase
        text-center
        bg-clip-text text-transparent bg-gradient-to-r;
    }

    p {
        @apply
        pt-2
        text-center
        font-light text-sm tracking-wide uppercase;
    }
}

/********** download buttons **********/

.download-buttons-container {
    @apply
    flex flex-col sm:flex-row gap-4;
}

.download-button {
    @apply
    px-4 py-2
    grid gap-x-2 gap-y-1 justify-items-start items-center;
    grid-template-areas: "icon tagline" "icon title";
    @apply
    bg-white
    border-4 border-transparent rounded-lg
    bg-clip-padding
    transition-all
    relative;

    &:hover,
    &:focus {
        @apply bg-white/90;
    }

    &:active {
        @apply bg-white/80;
    }

    &:before {
        content: '';
        @apply
        absolute inset-0
        m-[-4px]
        rounded-lg
        bg-gradient-to-tr from-indigo-500 via-sky-500 to-green-500
        z-[-1];
    }

    img {
        grid-area: icon;
    }

    p {
        @apply
        text-xs
        text-slate-900;
        grid-area: tagline;
    }

    h1 {
        @apply
        text-lg leading-4
        text-slate-900;
        grid-area: title;
    }
}

/********** logo **********/

#logo-container {
    @apply
    section-container
    pt-4 lg:pt-8;

    img {
        @apply w-6 lg:w-8;
    }
}

/********** header **********/

header {
    @apply relative;
}

#header-container {
    @apply
    section-container
    section-layout
    subsection-spacing
    gap-16;
}

#header-title {
    @apply
    font-bold
    text-6xl lg:text-8xl;
}

#header-description {
    @apply
    pt-2 lg:pt-4
    text-slate-400;
}

#header-download-buttons {
    @apply
    pt-8 lg:pt-16
    download-buttons-container
    items-start;
}

#header-image {
    @apply scale-125;
}

/********** features section **********/

#features {
    @apply section-spacing;
}

.features-subsection {
    @apply section-container section-layout subsection-spacing gap-4 lg:gap-16;
}

.feature-step-number {
    @apply
    absolute left-0 sm:-left-8 -top-8
    font-black text-9xl
    bg-clip-text text-transparent bg-gradient-to-br
    z-[-5]
    opacity-60;
}

.feature-title {
    @apply font-bold text-lg leading-4;
}

.feature-description {
    @apply pt-4;
}

.feature-selector-container {
    @apply
    pt-8 lg:pt-16
    grid grid-cols-4 gap-x-4;
}

.feature-selector {
    @apply
    flex flex-col items-center;

    .feature-selector-image {
        @apply
        relative;

        img {
            @apply
            border-2 border-white border-opacity-20 rounded-full;
        }

        &:before {
            content: "";
            @apply
            absolute inset-0 border-8 border-slate-900 rounded-full opacity-0
            transition duration-300;
        }

        &:after {
            content: "";
            @apply
            absolute inset-0 border-4 border-white rounded-full opacity-0
            transition duration-300;
        }
    }


    &.selected {
        .feature-selector-image {
            &:before {
                @apply opacity-100;
            }

            &:after {
                @apply opacity-100;
            }
        }
    }

    p {
        @apply
        pt-2 lg:pt-3
        text-xs
        text-center;
    }
}

.location-selector {
    p {
        @apply text-blue-400;
    }
}

.style-selector {
    p {
        @apply text-violet-400;
    }
}

#selected-location, #selected-style {
    @apply
    rounded-3xl lg:rounded-4xl
    transition duration-300;
}

.feature-selector-background {
    @apply
    w-full h-full
    absolute
    left-1/2 -translate-x-1/2
    top-1/2 -translate-y-1/2
    z-[-10];
}

#feature-selector-background-1 {
    @apply rect-plus-pattern feature-selector-background rotate-12 bg-blue-900;
}

#feature-selector-background-2 {
    @apply rect-plus-pattern feature-selector-background -rotate-6 bg-violet-900;
}

#feature-selector-background-3 {
    @apply rect-plus-pattern feature-selector-background rotate-3 bg-fuchsia-900;
}

#phone-background {
    @apply
    rect-plus-pattern
    h-full w-full
    absolute
    -left-4
    top-0 lg:-top-8
    -z-10
    bg-pink-800;
}

#phone-with-wallpaper {
    @apply
    w-3/4 lg:w-full
    mx-auto;
    transform: perspective(900px) rotateX(0deg) rotateY(24deg) rotateZ(-4deg);
}

/********** download section **********/

#download {
    @apply
    section-spacing
    relative;
}

#download-buttons {
    @apply
    download-buttons-container
    pt-16
    items-center
    sm:mx-auto sm:w-96;

    .download-button {
        @apply sm:flex-1;
    }
}

/********** footer **********/

footer {
    @apply
    section-container
    section-spacing
    pb-16 lg:pb-32
    flex flex-col lg:flex-row justify-between items-center
    font-light text-xs tracking-wide uppercase
    text-slate-400
    relative;

    a {
        @apply
        text-white
        hover:underline focus:underline;
    }

    #created-by {
        @apply pt-4 lg:pt-0;
    }

    #copyright {
        @apply pt-2 lg:pt-0;
    }
}

#footer-logo {
    @apply
    flex flex-col items-center
    lg:absolute
    lg:left-1/2 lg:-translate-x-1/2
    lg:top-0 lg:-translate-y-1/4;

    img {
        @apply w-6;
    }

    p {
        @apply
        font-normal text-base tracking-normal normal-case
        text-white;
    }
}


/********** plus patterns **********/

.full-plus-pattern {
    @apply
    absolute inset-x-0
    h-24 lg:h-48
    z-[-10]
    bg-white
    opacity-30;
}

#top-plus-pattern {
    @apply full-plus-pattern top-0;
    mask-image: url(../images/plus.svg), linear-gradient(to bottom, black, transparent);
    mask-composite: intersect;
}

#bottom-plus-pattern {
    @apply full-plus-pattern bottom-0;
    mask-image: url(../images/plus.svg), linear-gradient(to bottom, transparent, black);
    mask-composite: intersect;
}

.rect-plus-pattern {
    mask-image: url(../images/plus.svg);
}

.section-header-plus-pattern {
    @apply
    rect-plus-pattern
    w-3/5 h-16
    absolute
    top-0 -translate-y-2/3
    left-1/2 -translate-x-1/2
    z-[-10];
}

/********** waves **********/

.wave {
    @apply
    absolute
    left-1/2 -translate-x-1/2
    w-screen h-24
    bg-repeat-x bg-cover lg:bg-contain
    z-[-10];
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2));
}

#wave-1 {
    @apply wave;
    background-image: url(../images/waves-1.svg);
    @apply top-0 translate-y-64 lg:top-auto lg:bottom-0 lg:translate-y-16;
}

#wave-2 {
    @apply wave;
    background-image: url(../images/waves-2.svg);
    @apply bottom-0 sm:-bottom-8;
}
