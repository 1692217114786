@import "https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;700;900&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.static {
  position: static;
}

.relative {
  position: relative;
}

.bg-cyan-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.from-emerald-600 {
  --tw-gradient-from: #059669;
  --tw-gradient-to: #05966900;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300 {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-to: #a5b4fc00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-300 {
  --tw-gradient-from: #d8b4fe;
  --tw-gradient-to: #d8b4fe00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-rose-700 {
  --tw-gradient-from: #be123c;
  --tw-gradient-to: #be123c00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-sky-300 {
  --tw-gradient-from: #7dd3fc;
  --tw-gradient-to: #7dd3fc00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-cyan-800 {
  --tw-gradient-to: #155e75;
}

.to-indigo-600 {
  --tw-gradient-to: #4f46e5;
}

.to-purple-600 {
  --tw-gradient-to: #9333ea;
}

.to-purple-800 {
  --tw-gradient-to: #6b21a8;
}

.to-sky-600 {
  --tw-gradient-to: #0284c7;
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.text-fuchsia-400 {
  --tw-text-opacity: 1;
  color: rgb(232 121 249 / var(--tw-text-opacity));
}

.text-violet-400 {
  --tw-text-opacity: 1;
  color: rgb(167 139 250 / var(--tw-text-opacity));
}

html {
  font-size: 21px;
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-left: .5rem;
  padding-right: .5rem;
  font-family: Overpass, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  overflow-x: hidden;
}

.section-container {
  width: 100%;
}

@media (min-width: 640px) {
  .section-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .section-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .section-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .section-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .section-container {
    max-width: 1536px;
  }
}

.section-container {
  max-width: 24rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .section-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .section-container {
    max-width: 1280px;
  }
}

.section-layout {
  place-items: center;
  display: grid;
}

@media (min-width: 1024px) {
  .section-layout {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.section-spacing {
  margin-top: 12rem;
}

@media (min-width: 640px) {
  .section-spacing {
    margin-top: 13rem;
  }
}

@media (min-width: 1024px) {
  .section-spacing {
    margin-top: 16rem;
  }
}

.subsection-spacing {
  margin-top: 6rem;
}

@media (min-width: 1024px) {
  .subsection-spacing {
    margin-top: 6rem;
  }
}

.section-header {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.section-header h1 {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .025em;
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 1.875rem;
  font-weight: 900;
  line-height: 2.25rem;
}

@media (min-width: 640px) {
  .section-header h1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .section-header h1 {
    font-size: 3rem;
    line-height: 1;
  }
}

.section-header p {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .025em;
  padding-top: .5rem;
  font-size: .875rem;
  font-weight: 300;
  line-height: 1.25rem;
}

.download-buttons-container {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

@media (min-width: 640px) {
  .download-buttons-container {
    flex-direction: row;
  }
}

.download-button {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  background-clip: padding-box;
  border-width: 4px;
  border-color: #0000;
  border-radius: .5rem;
  grid-template-areas: "icon tagline"
                       "icon title";
  place-items: center start;
  gap: .25rem .5rem;
  padding: .5rem 1rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: grid;
  position: relative;
}

.download-button:hover, .download-button:focus {
  background-color: #ffffffe6;
}

.download-button:active {
  background-color: #fffc;
}

.download-button:before {
  content: "";
  z-index: -1;
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  --tw-gradient-from: #6366f1;
  --tw-gradient-to: #6366f100;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #0ea5e900;
  --tw-gradient-stops: var(--tw-gradient-from), #0ea5e9, var(--tw-gradient-to);
  --tw-gradient-to: #22c55e;
  border-radius: .5rem;
  margin: -4px;
  position: absolute;
  inset: 0;
}

.download-button img {
  grid-area: icon;
}

.download-button p {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
  grid-area: tagline;
  font-size: .75rem;
  line-height: 1rem;
}

.download-button h1 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
  grid-area: title;
  font-size: 1.125rem;
  line-height: 1rem;
}

#logo-container {
  width: 100%;
  padding-top: 1rem;
}

@media (min-width: 640px) {
  #logo-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  #logo-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  #logo-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  #logo-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  #logo-container {
    max-width: 1536px;
  }
}

#logo-container {
  max-width: 24rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #logo-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  #logo-container {
    max-width: 1280px;
  }
}

@media (min-width: 1024px) {
  #logo-container {
    padding-top: 2rem;
  }
}

#logo-container img {
  width: 1.5rem;
}

@media (min-width: 1024px) {
  #logo-container img {
    width: 2rem;
  }
}

header {
  position: relative;
}

#header-container {
  width: 100%;
  gap: 4rem;
}

@media (min-width: 640px) {
  #header-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  #header-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  #header-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  #header-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  #header-container {
    max-width: 1536px;
  }
}

#header-container {
  max-width: 24rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #header-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  #header-container {
    max-width: 1280px;
  }
}

#header-container {
  place-items: center;
  display: grid;
}

@media (min-width: 1024px) {
  #header-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

#header-container {
  margin-top: 6rem;
}

@media (min-width: 1024px) {
  #header-container {
    margin-top: 6rem;
  }
}

#header-title {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1;
}

@media (min-width: 1024px) {
  #header-title {
    font-size: 6rem;
    line-height: 1;
  }
}

#header-description {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
  padding-top: .5rem;
}

@media (min-width: 1024px) {
  #header-description {
    padding-top: 1rem;
  }
}

#header-download-buttons {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding-top: 2rem;
  display: flex;
}

@media (min-width: 640px) {
  #header-download-buttons {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  #header-download-buttons {
    padding-top: 4rem;
  }
}

#header-image {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#features {
  margin-top: 12rem;
}

@media (min-width: 640px) {
  #features {
    margin-top: 13rem;
  }
}

@media (min-width: 1024px) {
  #features {
    margin-top: 16rem;
  }
}

.features-subsection {
  width: 100%;
  gap: 1rem;
}

@media (min-width: 640px) {
  .features-subsection {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .features-subsection {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .features-subsection {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .features-subsection {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .features-subsection {
    max-width: 1536px;
  }
}

.features-subsection {
  max-width: 24rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .features-subsection {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .features-subsection {
    max-width: 1280px;
  }
}

.features-subsection {
  place-items: center;
  display: grid;
}

@media (min-width: 1024px) {
  .features-subsection {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.features-subsection {
  margin-top: 6rem;
}

@media (min-width: 1024px) {
  .features-subsection {
    gap: 4rem;
    margin-top: 6rem;
  }
}

.feature-step-number {
  z-index: -5;
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  color: #0000;
  opacity: .6;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 8rem;
  font-weight: 900;
  line-height: 1;
  position: absolute;
  top: -2rem;
  left: 0;
}

@media (min-width: 640px) {
  .feature-step-number {
    left: -2rem;
  }
}

.feature-title {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1rem;
}

.feature-description {
  padding-top: 1rem;
}

.feature-selector-container {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 1rem;
  padding-top: 2rem;
  display: grid;
}

@media (min-width: 1024px) {
  .feature-selector-container {
    padding-top: 4rem;
  }
}

.feature-selector {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.feature-selector .feature-selector-image {
  position: relative;
}

.feature-selector .feature-selector-image img {
  border-width: 2px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  border-radius: 9999px;
}

.feature-selector .feature-selector-image:before {
  content: "";
  --tw-border-opacity: 1;
  border-width: 8px;
  border-color: rgb(15 23 42 / var(--tw-border-opacity));
  opacity: 0;
  border-radius: 9999px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  inset: 0;
}

.feature-selector .feature-selector-image:after {
  content: "";
  --tw-border-opacity: 1;
  border-width: 4px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  opacity: 0;
  border-radius: 9999px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  inset: 0;
}

.feature-selector.selected .feature-selector-image:before, .feature-selector.selected .feature-selector-image:after {
  opacity: 1;
}

.feature-selector p {
  text-align: center;
  padding-top: .5rem;
  font-size: .75rem;
  line-height: 1rem;
}

@media (min-width: 1024px) {
  .feature-selector p {
    padding-top: .75rem;
  }
}

.location-selector p {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.style-selector p {
  --tw-text-opacity: 1;
  color: rgb(167 139 250 / var(--tw-text-opacity));
}

#selected-location, #selected-style {
  border-radius: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (min-width: 1024px) {
  #selected-location, #selected-style {
    border-radius: 2rem;
  }
}

.feature-selector-background {
  z-index: -10;
  height: 100%;
  width: 100%;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  top: 50%;
  left: 50%;
}

#feature-selector-background-1 {
  --tw-rotate: 12deg;
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
  z-index: -10;
  height: 100%;
  width: 100%;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-mask-image: url("plus.7ce0c865.svg");
  mask-image: url("plus.7ce0c865.svg");
}

#feature-selector-background-2 {
  --tw-rotate: -6deg;
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity));
  z-index: -10;
  height: 100%;
  width: 100%;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-mask-image: url("plus.7ce0c865.svg");
  mask-image: url("plus.7ce0c865.svg");
}

#feature-selector-background-3 {
  --tw-rotate: 3deg;
  --tw-bg-opacity: 1;
  background-color: rgb(112 26 117 / var(--tw-bg-opacity));
  z-index: -10;
  height: 100%;
  width: 100%;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-mask-image: url("plus.7ce0c865.svg");
  mask-image: url("plus.7ce0c865.svg");
}

#phone-background {
  z-index: -10;
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(157 23 77 / var(--tw-bg-opacity));
  position: absolute;
  top: 0;
  left: -1rem;
  -webkit-mask-image: url("plus.7ce0c865.svg");
  mask-image: url("plus.7ce0c865.svg");
}

@media (min-width: 1024px) {
  #phone-background {
    top: -2rem;
  }
}

#phone-with-wallpaper {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  #phone-with-wallpaper {
    width: 100%;
  }
}

#phone-with-wallpaper {
  transform: perspective(900px)rotateX(0)rotateY(24deg)rotateZ(-4deg);
}

#download {
  margin-top: 12rem;
  position: relative;
}

@media (min-width: 640px) {
  #download {
    margin-top: 13rem;
  }
}

@media (min-width: 1024px) {
  #download {
    margin-top: 16rem;
  }
}

#download-buttons {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 4rem;
  display: flex;
}

@media (min-width: 640px) {
  #download-buttons {
    width: 24rem;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }

  #download-buttons .download-button {
    flex: 1;
  }
}

footer {
  text-transform: uppercase;
  letter-spacing: .025em;
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4rem;
  font-size: .75rem;
  font-weight: 300;
  line-height: 1rem;
  display: flex;
  position: relative;
}

@media (min-width: 640px) {
  footer {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  footer {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  footer {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  footer {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  footer {
    max-width: 1536px;
  }
}

footer {
  max-width: 24rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  footer {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  footer {
    max-width: 1280px;
  }
}

footer {
  margin-top: 12rem;
}

@media (min-width: 640px) {
  footer {
    margin-top: 13rem;
  }
}

@media (min-width: 1024px) {
  footer {
    flex-direction: row;
    margin-top: 16rem;
    padding-bottom: 8rem;
  }
}

footer a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

footer a:hover, footer a:focus {
  text-decoration-line: underline;
}

footer #created-by {
  padding-top: 1rem;
}

@media (min-width: 1024px) {
  footer #created-by {
    padding-top: 0;
  }
}

footer #copyright {
  padding-top: .5rem;
}

@media (min-width: 1024px) {
  footer #copyright {
    padding-top: 0;
  }
}

#footer-logo {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  #footer-logo {
    --tw-translate-x: -50%;
    --tw-translate-y: -25%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    position: absolute;
    top: 0;
    left: 50%;
  }
}

#footer-logo img {
  width: 1.5rem;
}

#footer-logo p {
  text-transform: none;
  letter-spacing: 0;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.full-plus-pattern {
  z-index: -10;
  height: 6rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  opacity: .3;
  position: absolute;
  left: 0;
  right: 0;
}

@media (min-width: 1024px) {
  .full-plus-pattern {
    height: 12rem;
  }
}

#top-plus-pattern {
  z-index: -10;
  height: 6rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  opacity: .3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (min-width: 1024px) {
  #top-plus-pattern {
    height: 12rem;
  }
}

#top-plus-pattern {
  -webkit-mask-image: url("plus.7ce0c865.svg"), linear-gradient(#000, #0000);
  mask-image: url("plus.7ce0c865.svg"), linear-gradient(#000, #0000);
  -webkit-mask-composite: source-in;
  mask-composite: intersect;
}

#bottom-plus-pattern {
  z-index: -10;
  height: 6rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  opacity: .3;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 1024px) {
  #bottom-plus-pattern {
    height: 12rem;
  }
}

#bottom-plus-pattern {
  -webkit-mask-image: url("plus.7ce0c865.svg"), linear-gradient(#0000, #000);
  mask-image: url("plus.7ce0c865.svg"), linear-gradient(#0000, #000);
  -webkit-mask-composite: source-in;
  mask-composite: intersect;
}

.rect-plus-pattern {
  -webkit-mask-image: url("plus.7ce0c865.svg");
  mask-image: url("plus.7ce0c865.svg");
}

.section-header-plus-pattern {
  z-index: -10;
  height: 4rem;
  width: 60%;
  --tw-translate-y: -66.6667%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-mask-image: url("plus.7ce0c865.svg");
  mask-image: url("plus.7ce0c865.svg");
}

.wave {
  z-index: -10;
  height: 6rem;
  width: 100vw;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-repeat: repeat-x;
  background-size: cover;
  position: absolute;
  left: 50%;
}

@media (min-width: 1024px) {
  .wave {
    background-size: contain;
  }
}

.wave {
  -webkit-mask-image: linear-gradient(to right, #0003, #000c, #0003);
  mask-image: linear-gradient(to right, #0003, #000c, #0003);
}

#wave-1 {
  z-index: -10;
  height: 6rem;
  width: 100vw;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-repeat: repeat-x;
  background-size: cover;
  position: absolute;
  left: 50%;
}

@media (min-width: 1024px) {
  #wave-1 {
    background-size: contain;
  }
}

#wave-1 {
  --tw-translate-y: 16rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url("waves-1.4200d4db.svg");
  top: 0;
  -webkit-mask-image: linear-gradient(to right, #0003, #000c, #0003);
  mask-image: linear-gradient(to right, #0003, #000c, #0003);
}

@media (min-width: 1024px) {
  #wave-1 {
    --tw-translate-y: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    top: auto;
    bottom: 0;
  }
}

#wave-2 {
  z-index: -10;
  height: 6rem;
  width: 100vw;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-repeat: repeat-x;
  background-size: cover;
  position: absolute;
  left: 50%;
}

@media (min-width: 1024px) {
  #wave-2 {
    background-size: contain;
  }
}

#wave-2 {
  background-image: url("waves-2.4b4e57a4.svg");
  bottom: 0;
  -webkit-mask-image: linear-gradient(to right, #0003, #000c, #0003);
  mask-image: linear-gradient(to right, #0003, #000c, #0003);
}

@media (min-width: 640px) {
  #wave-2 {
    bottom: -2rem;
  }
}

@media (min-width: 1024px) {
  .lg\:order-first {
    order: -9999;
  }
}

/*# sourceMappingURL=index.5fe469c6.css.map */
